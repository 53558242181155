import axios from '../axios'

class UserService {
  static async store(params) {
    const resp = await axios.post('/user', params)
    return resp.data
  }

  static async update(params) {
    const resp = await axios.put(`/user`, { ...params, _method: 'put' })
    return resp.data
  }

  // static async delete(id) {
  //   const resp = await axios.delete(`/users/${id}`)
  //   return resp.data
  // }

  // static async restore(id) {
  //   const resp = await axios.get(`/users/restore/${id}`)
  //   return resp.data
  // }
}

export default UserService

import { createTheme } from '@mui/material'
import palette from './scss/_variables.scss'

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist'
  },
  palette: {
    primary: {
      main: '#002069'
    },
    neutral: {
      main: '#eceff1'
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'white'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          textTransform: 'none',
          color: 'white',
          '&.Mui-selected': {
            color: 'white',
            fontWeight: '550'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.light_blue_primary
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    }
  }
})

export default theme

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Tab, Tabs } from '@mui/material'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import ConstructionIcon from '@mui/icons-material/Construction'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PersonIcon from '@mui/icons-material/Person'
import SpeedIcon from '@mui/icons-material/Speed'
import AuthService from 'api/services/auth.service'
import Loader from 'components/Loader/Loader'
import Styles from './_Header.module.scss'

const Header = () => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [loading, setLoading] = useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    setLoading(true)
    try {
      await AuthService.logout()
      navigate('login')
    } catch (error) {
      navigate('login')
    }
  }

  const [value, setValue] = useState('dashboard')

  const handleChange = (_, value) => {
    setValue(value)
    if (value === 'profile') {
      navigate(`/${value}`)
    } else {
      navigate(`/`)
    }
  }

  const [currentWidth, setCurrentWidth] = useState()

  const resize = () => {
    setCurrentWidth(window.innerWidth)
  }

  window.addEventListener('resize', () => {
    resize()
  })

  useEffect(() => {
    resize()
  }, [])

  return (
    <>
      <Box className={Styles.header}>
        <Box className={Styles.header__title}>
          <img src="Logo_Header.png" alt="" />
        </Box>
        <Box className={Styles.header__title_triangle} />
        <Box className={Styles.header__menu}>
          {currentWidth > 680 && (
            <Box sx={{ width: '100%', padding: '0 1rem' }}>
              <Tabs value={value} onChange={handleChange} aria-label="secondary tabs example" variant="fullWidth">
                <Tab
                  sx={{ minHeight: '4rem' }}
                  value="dashboard"
                  label="Dashboard"
                  icon={<SpeedIcon fontSize="medium" />}
                  iconPosition="start"
                />
                <Tab
                  sx={{ minHeight: '4rem' }}
                  value="toolbar"
                  label="Toolbar"
                  icon={<ConstructionIcon fontSize="medium" />}
                  iconPosition="start"
                />
                {currentWidth > 840 && (
                  <Tab
                    sx={{ minHeight: '4rem' }}
                    value="notifications"
                    label="Notifications"
                    icon={<NotificationsIcon fontSize="medium" />}
                    iconPosition="start"
                  />
                )}
                {currentWidth > 1090 && (
                  <Tab
                    sx={{ minHeight: '4rem' }}
                    value="profile"
                    label="Profile"
                    icon={<PersonIcon fontSize="medium" />}
                    iconPosition="start"
                  />
                )}
              </Tabs>
            </Box>
          )}
          {currentWidth <= 840 && (
            <IconButton>
              <NotificationsIcon fontSize="medium" sx={{ color: 'white' }} />
            </IconButton>
          )}
          <IconButton
            onClick={handleClick}
            size="small"
            className={Styles.profile_button}
            disableRipple
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <Box className={Styles.header__menu_item}>
              <img src="User_Logo.png" alt="" />
              {currentWidth > 980 && <span>REXXAI Admin</span>}
            </Box>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            {currentWidth <= 1090 && (
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
            )}
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {loading && <Loader />}
    </>
  )
}

export default Header

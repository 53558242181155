import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Box, Button, FormControl, TextField } from '@mui/material'
import AuthService from 'api/services/auth.service'
import Loader from 'components/Loader/Loader'
import LoaderBackground from 'components/LoaderBackground/LoaderBackground'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import { loginFormSchema } from 'utils/schemas/loginFormSchema'
import Styles from './_Login.module.scss'

const Login = () => {
  const navigate = useNavigate()

  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [loading, setLoading] = useState(false)
  const loginForm = { email: '', password: '' }

  const handleLogin = async form => {
    try {
      setLoading(true)
      const resp = await AuthService.login(form.email, form.password)
      localStorage.setItem('token', resp.token)
      localStorage.setItem('user', JSON.stringify(resp.user))
      setAlert({ open: true, severity: 'success', message: 'Logged in with success. Redirecting' })
      setTimeout(() => {
        navigate('/')
      }, 2000)
    } catch (error) {
      console.log(error)
      setLoading(false)
      const message = error.response?.data?.error.includes('Incorrect credentials')
        ? 'The email or the password are incorrect'
        : 'Something went wrong, try again later'
      setAlert({ severity: 'error', open: true, message: message })
    }
  }

  const { errors, touched, values, handleSubmit, setFieldValue } = useFormik({
    initialValues: loginForm,
    enableReinitialize: true,
    validationSchema: loginFormSchema,
    onSubmit: vals => {
      handleLogin(vals)
    }
  })

  const handleFormChange = (key, value) => {
    setFieldValue(key, value)
  }

  useEffect(() => {
    setAlert({ open: false, severity: '', message: '' })
    setLoading(false)
  }, [])

  return (
    <>
      <LoaderBackground />
      <Box className={Styles.layout}>
        <Box className={Styles.login__card}>
          <Box className={Styles.login__card__img}>
            <img src="Logo_SideMenu.png" alt="" />
          </Box>
          <Box className={Styles.login__card__title}> Dashboard</Box>
          <Box className={Styles.login__card__body}>
            <FormControl className={Styles.input}>
              <TextField
                label="Email"
                size="small"
                variant="outlined"
                value={values.email}
                error={touched?.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                onChange={e => handleFormChange('email', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Password"
                size="small"
                variant="outlined"
                type="password"
                value={values.password}
                error={touched?.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                onChange={e => handleFormChange('password', e.target.value)}
              />
            </FormControl>
          </Box>
          <Box className={Styles.login__card__footer}>
            <Button
              sx={{ marginBottom: '1rem', width: '10rem' }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}>
              Login
            </Button>
            <span>
              Don't have an account?{' '}
              <span className={Styles.link} onClick={() => navigate('/register')}>
                Register here
              </span>
            </span>
          </Box>
        </Box>
      </Box>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      {loading && <Loader />}
    </>
  )
}

export default Login

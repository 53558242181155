import { useEffect, useState } from 'react'
import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction'
import MenuIcon from '@mui/icons-material/Menu'
import SpeedIcon from '@mui/icons-material/Speed'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import Styles from './_Dashboard.module.scss'

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const openSidebarMenu = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [currentWidth, setCurrentWidth] = useState()

  const resize = () => {
    setCurrentWidth(window.innerWidth)
  }

  window.addEventListener('resize', () => {
    resize()
  })

  useEffect(() => {
    resize()
  }, [])
  return (
    <Box className={Styles.dashboard}>
      {currentWidth <= 680 && (
        <>
          <IconButton
            onClick={handleClick}
            size="small"
            className={Styles.sidebar_button}
            disableRipple
            aria-controls={openSidebarMenu ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openSidebarMenu ? 'true' : undefined}>
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openSidebarMenu}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SpeedIcon fontSize="small" />
              </ListItemIcon>
              Dashboard
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <ConstructionIcon fontSize="medium" />
              </ListItemIcon>
              Toolbar
            </MenuItem>
          </Menu>
        </>
      )}
      <Grid container spacing={4}>
        <Grid item lg={7} xs={12}>
          <Box className={Styles.box} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box className={Styles.banner__left}>
              <span>
                <b>Buenos días!</b> REXXAI admin
              </span>
              <span>
                <b>¿En que te puedo ayudar?</b>
              </span>
              <input type="text" className={Styles.search_input} placeholder="Escribe lo que buscas" />
            </Box>
            <Box className={Styles.banner__right}>
              <img src="User_Logo.png" alt="" />
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', margin: '2rem 0', color: 'white' }}>
            <h1>Tus módulos AI personalizados</h1>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={4} xs={12} sx={{ display: 'flex' }}>
              <Box
                className={Styles.box}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <QueryStatsIcon sx={{ fontSize: '5rem', marginBottom: '1rem' }} />
                <span style={{ fontSize: '1.5rem', textAlign: 'center' }}>Proyección Financiera</span>
              </Box>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ display: 'flex' }}>
              <Box
                className={Styles.box}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <QueryStatsIcon sx={{ fontSize: '5rem', marginBottom: '1rem' }} />
                <span style={{ fontSize: '1.5rem', textAlign: 'center' }}>Análisis de la Industria</span>
              </Box>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ display: 'flex' }}>
              <Box
                className={Styles.box}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <QueryStatsIcon sx={{ fontSize: '5rem', marginBottom: '1rem' }} />
                <span style={{ fontSize: '1.5rem', textAlign: 'center' }}>Análisis de Competidores</span>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1} xs={12} />
        <Grid item lg={4} xs={12}>
          <Box className={Styles.news_container}>
            <Box className={Styles.box__no_border} sx={{ display: 'flex', marginBottom: '1rem' }}>
              <Box className={Styles.card__left}>
                <h2>La Tecnologìa...</h2>
                <span>
                  Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente texto aleatorio. Tiene
                  sus raices en una pieza cl´sica de la literatura del Latin, que data del año 45 antes de Cristo,
                  haciendo que este adquiera mas de 2000 años de antigueda
                </span>
              </Box>
              <Box className={Styles.card__right}>
                <img
                  src="https://e00-expansion.uecdn.es/assets/multimedia/imagenes/2020/12/15/16080660925480.jpg"
                  alt=""
                />
              </Box>
            </Box>
            <Box className={Styles.box__no_border} sx={{ display: 'flex', marginBottom: '1rem' }}>
              <Box />
              <Box>
                <img src="" alt="" />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard

export class User {
  id
  name
  last_name
  email
  password
  password_confirmation
  created_at
  updated_at
  deleted_at

  constructor() {
    this.id = 0
    this.name = ''
    this.last_name = ''
    this.email = ''
    this.phone = ''
    this.created_at = new Date()
    this.updated_at = new Date()
  }
}

export default User

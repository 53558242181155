import { useEffect, useState } from 'react'
import { Avatar, Box, Button, Divider, FormControl, TextField } from '@mui/material'
import AuthService from 'api/services/auth.service'
import UserService from 'api/services/user.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import SideModal from 'components/SideModal/SideModal'
import User from 'models/User'
import Styles from './_Profile.module.scss'

const Profile = () => {
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(new User())
  const [userForm, setUserForm] = useState(new User())
  const [showSideModal, setShowSideModal] = useState(false)

  const getUser = async () => {
    try {
      const resp = await AuthService.getCurrentUser()
      setUser(resp)
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Profile, try again later'
      })
    }
  }

  const getCapitalLetters = user => {
    return `${user.name.charAt(0)}${user.last_name.charAt(0)}`
  }

  const handleFormChange = (key, value) => {
    setUserForm(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSaveUser = async () => {
    setLoading(true)
    try {
      await UserService.update(userForm)
      setLoading(false)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'success', message: 'Profile updated with success' })
      getUser()
    } catch (error) {
      setLoading(false)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while saving Profile, try again later' })
    }
  }

  const handleEditProfile = () => {
    setUserForm({ ...user })
    setShowSideModal(true)
  }

  useEffect(() => {
    getUser()
    setLoading(false)
  }, [])

  return (
    <>
      <h1>Profile</h1>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '30rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '1.5rem' }}>
            <Avatar sx={{ width: 72, height: 72, margin: 1 }}>{getCapitalLetters(user)}</Avatar>
            <b>
              {user.name} {user.last_name}
            </b>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size="small"
                variant="outlined"
                color="neutral"
                sx={{ fontSize: '.65rem' }}
                onClick={handleEditProfile}>
                Editar
              </Button>
            </Box>
            <Divider sx={{ margin: '1rem 0', borderWidth: '3px', background: '#ccc' }} />
            <h3>Personal information</h3>
            <br />
            <Box className={Styles.profile__item}>
              <small>Name</small>
              <span>
                {user.name} {user.last_name}
              </span>
            </Box>
            <Divider sx={{ margin: '.75rem 0' }} />
            <Box className={Styles.profile__item}>
              <small>Email</small>
              <span>{user.email}</span>
            </Box>
            <Divider sx={{ margin: '.75rem 0' }} />
            <Box className={Styles.profile__item}>
              <small>Phone</small>
              <span>{user.phone || 'Not defined'}</span>
            </Box>
            <Divider sx={{ margin: '.75rem 0', background: '#ccc' }} />
            <h3>Plan</h3>
          </Box>
        </Box>
      </Box>
      <SideModal
        open={showSideModal}
        title="Update profile"
        loading={loading}
        onCancel={() => setShowSideModal(false)}
        onConfirm={handleSaveUser}>
        {userForm && (
          <>
            <FormControl className={Styles.input}>
              <TextField
                label="Name"
                size="small"
                variant="outlined"
                value={userForm.name}
                onChange={e => handleFormChange('name', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="LastName"
                size="small"
                variant="outlined"
                value={userForm.last_name}
                onChange={e => handleFormChange('last_name', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Email"
                size="small"
                variant="outlined"
                value={userForm.email}
                onChange={e => handleFormChange('email', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Phone"
                size="small"
                variant="outlined"
                value={userForm.phone}
                onChange={e => handleFormChange('phone', e.target.value)}
              />
            </FormControl>
          </>
        )}
      </SideModal>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default Profile

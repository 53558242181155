import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import Header from './components/Header/Header'
import Styles from './_Layout.module.scss'

const Layout = () => {
  return (
    <Box className={Styles.layout}>
      <Header />
      <Box className={Styles.layout__content}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout
